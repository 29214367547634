<template>
  <div>
    <v-dialog eager v-model="dialogDatos" max-width="600">
      <DatosAuxiliaresExamen
        @close="dialogDatos = false"
        @completado="getActaExamen"
      ></DatosAuxiliaresExamen>
    </v-dialog>
    <h3>{{ examen.materia.searchTag }}</h3>
    <div class="d-flex">
      <div>
        <v-select
          dense
          label="Estado"
          class="mr-1"
          hide-details
          outlined
          v-model="opcion"
          :items="estado"
        ></v-select>
      </div>
      <div>
        <v-btn color="info" @click="cambiarTodos">ejecutar</v-btn>
      </div>

      <div class="w-100 d-flex justify-content-around">
        <!--        <div>-->
        <!--          <v-btn color="success" @click="getActaExamen">Imprimir</v-btn>-->
        <!--        </div>-->
        <v-btn color="error" @click="cerrarPlanilla">Cerrar</v-btn>
        <v-btn
          target="_blank"
          color="success"
          href="https://youtu.be/eSjCaYzv-qA"
          >Tutorial</v-btn
        >
      </div>
    </div>
    <v-data-table
      :sort-by.sync="sort"
      v-model="selectedItems"
      show-select
      :items="alumnoExamens"
      :headers="headers"
      :items-per-page="-1"
    >
      <template v-slot:item.estado="{ item }">
        <v-select
          :disabled="esDisabled(item)"
          :error="!!item.error"
          :error-messages="item.error"
          dense
          outlined
          clearable
          :items="estado"
          v-model="item.notaAlumnoExamen.estado"
          @change="changeItem(item.notaAlumnoExamen)"
        ></v-select>
      </template>

      <template v-slot:item.nota_oral="{ item }">
        <!--        <v-select-->
        <!--            :items="notas"-->
        <!--            :disabled="esDisabled(item)"-->
        <!--            clearable-->
        <!--            placeholder="Ingrese nota Oral"-->
        <!--            outlined-->
        <!--            dense-->
        <!--            v-model="item.notaAlumnoExamen.nota_oral"-->
        <!--            @change="changeItem(item.notaAlumnoExamen)"-->
        <!--        ></v-select>-->
        <v-text-field
          :disabled="esDisabled(item)"
          clearable
          placeholder="Ingrese nota Oral"
          outlined
          dense
          v-model="item.notaAlumnoExamen.nota_oral"
          @change="changeItem(item.notaAlumnoExamen)"
        ></v-text-field>
      </template>
      <template v-slot:item.nota_escrito="{ item }">
        <!--        <v-select-->
        <!--            :items="notas"-->
        <!--            :disabled="esDisabled(item)"-->
        <!--            clearable-->
        <!--            placeholder="Ingrese nota Escrito"-->
        <!--            outlined-->
        <!--            dense-->
        <!--            v-model="item.notaAlumnoExamen.nota_escrito"-->
        <!--            @change="changeItem(item.notaAlumnoExamen)"-->
        <!--        ></v-select>-->
        <v-text-field
          :disabled="esDisabled(item)"
          clearable
          placeholder="Ingrese nota Escrito"
          outlined
          dense
          v-model="item.notaAlumnoExamen.nota_escrito"
          @change="changeItem(item.notaAlumnoExamen)"
        ></v-text-field>
      </template>
      <template v-slot:item.nota="{ item }">
        <!--        <v-select-->
        <!--            :items="notas"-->
        <!--            :disabled="esDisabled(item)"-->
        <!--            clearable-->
        <!--            placeholder="Ingrese Nota Final"-->
        <!--            outlined-->
        <!--            :error="!!item.error"-->
        <!--            :error-messages="item.error"-->
        <!--            dense-->
        <!--            v-model="item.notaAlumnoExamen.nota"-->
        <!--            @change="changeItem(item.notaAlumnoExamen)"-->
        <!--        ></v-select>-->
        <v-text-field
          :disabled="esDisabled(item)"
          clearable
          placeholder="Ingrese nota Final"
          outlined
          dense
          v-model="item.notaAlumnoExamen.nota"
          @change="changeItem(item.notaAlumnoExamen)"
        ></v-text-field>
      </template>

      <template v-slot:item.observaciones="{ item }">
        <div class="d-flex">
          <div class="">
            <v-switch
              :disabled="esDisabled(item)"
              dense
              v-model="item.notaAlumnoExamen.observaciones"
              @change="switchObservaciones(item)"
            ></v-switch>
          </div>
          <div v-if="mostrarObservaciones(item)">
            <v-textarea
              :disabled="esDisabled(item)"
              dense
              hide-details
              outlined
              label="Observaciones"
              v-model="item.notaAlumnoExamen.observaciones"
              @change="changeItem(item.notaAlumnoExamen)"
            ></v-textarea>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DatosAuxiliaresExamen from "@/viewsNew/alumno-examen/DatosAuxiliaresExamen.vue";

export default {
  name: "PlanillaDigitalExamen",
  components: { DatosAuxiliaresExamen },
  props: {
    examen_id: [String, Number],
  },
  data() {
    return {
      dialogDatos: false,
      notas: [
        0,
        0.5,
        1,
        1.5,
        2,
        2.5,
        3,
        3.5,
        4,
        4.5,
        5,
        5.5,
        6,
        6.5,
        7,
        7.5,
        8,
        8.5,
        9,
        9.5,
        10,
      ],
      sort: "alumno.apellido",
      opcion: null,
      estado: [
        { text: "Aprobó", value: "aprobado" },
        { text: "Desaprobó", value: "desaprobado" },
        // { text: "Promocionó", value: "promocionado" },
        { text: "Ausente", value: "ausente" },
        // { text: "Abandonó", value: "abandono" },
        { text: "Final abierto", value: "sin informar" },
        // { text: "Oyente", value: "oyente" },
      ],
      selectedItems: [],
    };
  },
  watch: {
    examen() {
      this.getAlumnoExamens({
        filters: { examen_id: this.examen_id },
        expand: [
          // "userCampus",
          "alumno",
          "examen",
          "user",
          // "otrosCursados",
        ].toString(),
      });
    },
    examen_id() {
      this.getExamen(this.examen_id);
    },
  },
  computed: {
    ...mapState("AlumnoExamenModule", ["alumnoExamens"]),
    ...mapState("ExamenModule", ["examen"]),

    aprobados() {
      return this.alumnoExamens.filter(
        (el) => el.notaAlumnoExamen.estado === "aprobado"
      ).length;
    },
    desaprobados() {
      return this.alumnoExamens.filter(
        (el) => el.notaAlumnoExamen.estado === "desaprobado"
      ).length;
    },
    ausentes() {
      return this.alumnoExamens.filter(
        (el) => el.notaAlumnoExamen.estado === "ausente"
      ).length;
    },
    sinInformar() {
      return this.alumnoExamens.filter(
        (el) => el.notaAlumnoExamen.estado === "sin informar"
      ).length;
    },

    headers() {
      return [
        // { text: "Sin Informar", value: "sin_informar", filterable: true },
        // { text: "id", value: "id", filterable: true },
        { text: "Apellido", value: "alumno.apellido", filterable: true },
        { text: "Nombre", value: "alumno.nombre", filterable: true },
        // { text: "Email", value: "alumno.email", filterable: true },
        { text: "Condicion", value: "condicion", filterable: true },
        { text: "Oral", value: "nota_oral", filterable: true },
        { text: "Escrito", value: "nota_escrito", filterable: true },
        { text: "Nota Final", value: "nota", filterable: true },
        { text: "Estado", value: "estado", filterable: true },
        // { text: "Promociono", value: "promociono", filterable: true },
        // { text: "No Inicio", value: "noinicio", filterable: true },
        // { text: "Abandono", value: "abandono", filterable: true },
        { text: "Observaciones", value: "observaciones", filterable: true },
        // { text: "Promociono", value: "nota_promocion", filterable: true },
        // { text: "Telefono", value: "user.telefono", filterable: true },
        // { text: "Roles", value: "user.roles", filterable: true },
        // {
        //   text: "Acciones",
        //   value: "actions",
        //   filterable: false,
        //   nosearch: true,
        // },
      ];
    },
  },
  methods: {
    ...mapActions("DocenteExamenModule", ["actaExamen"]),
    ...mapActions("AlumnoExamenModule", ["getAlumnoExamens"]),
    ...mapActions("ExamenModule", ["getExamen"]),
    ...mapActions("NotaAlumnoExamenModule", [
      "updateNotaAlumnoExamen",
      "addNotaAlumnoExamen",
    ]),

    getActaExamen() {
      this.actaExamen(this.examen);
    },

    clearErrors() {
      this.alumnoExamens.map(function (el) {
        el.error = null;
        el.errorPromocion = null;
        el.errorAbandono = null;
      });
    },

    validarCompleto() {
      let error = false;
      this.alumnoExamens.map(function (el) {
        let notaAlumno = el.notaAlumnoExamen;
        if (
          !notaAlumno.estado ||
          ((notaAlumno.estado === "aprobado" ||
            notaAlumno.estado === "desaprobado") &&
            !notaAlumno.nota)
        ) {
          el.error = "Debe Completar el estado del alumno";
          error = true;
        }
      });
      this.alumnoExamens.splice(0, 0);

      return !error;
    },

    esDisabled(item) {
      return item.notaAlumnoExamen.cerrado ? true : false;
    },

    async cerrarPlanilla() {
      if (!this.validarCompleto()) {
        console.log(console.log());
        return false;
      }
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text:
          "Antes de cerrar asegurese de que la planilla que imprimió concuerda con los datos que figuran en la pantalla. Una vez cerrada no podra modificarla" +
          "<br> Aprobados: " +
          this.aprobados +
          "<br> Desaprobados: " +
          this.desaprobados +
          "<br> Ausentes: " +
          this.ausentes +
          "<br> Sin Informar: " +
          this.sinInformar,
        title: "Cerrar Planilla",
      });
      if (res) {
        await this.cerrarTodas();
        this.dialogDatos = true;
      }
    },

    cerrarTodas() {
      for (var i in this.alumnoExamens) {
        let item = this.alumnoExamens[i].notaAlumnoExamen;
        if (!item.cerrado) {
          item.cerrado = 1;
          this.changeItem(item);
        }
      }
    },

    getPlanillas() {
      if (!this.validarCompleto()) {
        console.log(console.log());
        return false;
      }
      const ids = [this.cursado_id];
      this.getPlanillaDigital({
        ids: ids,
        cursado: this.examen.materia.searchTag,
      });
    },

    cambiarTodos() {
      for (var i in this.selectedItems) {
        let item = this.selectedItems[i].notaAlumnoExamen;
        item.estado = this.opcion;
        this.changeItem(item);
      }
      this.opcion = null;
      this.selectedItems = [];
    },

    switchObservaciones(item) {
      console.log(item);
      if (item.notaAlumnoExamen.observaciones) {
        item.notaAlumnoExamen.observaciones = " ";
      } else {
        item.notaAlumnoExamen.observaciones = null;
      }
      this.changeItem(item.notaAlumnoExamen);
    },

    mostrarObservaciones(item) {
      return this.tieneObservaciones(item);
    },

    tieneObservaciones(item) {
      return item.notaAlumnoExamen.observaciones;
    },

    async changeItem(item) {
      const aprobo = 6;
      this.clearErrors();
      if (item.nota) {
        if (item.nota >= aprobo) {
          item.estado = "aprobado";
        } else if (item.nota >= 0) {
          item.estado = "desaprobado";
        } else {
          item.estado = null;
        }
      }
      this.updateNotaAlumnoExamen(item);
    },
  },
  created() {
    this.getExamen({
      id: this.examen_id,
      expand: [
        "materia",
        "promocionados",
        "alumnoExamen",
        "carrera",
      ].toString(),
    });
  },
};
</script>

<style scoped></style>
